import { getIconColor } from "@lib/utils"
import clsx from "clsx"
import React from "react"

const CheckCircle = ({ className, color }) => {
  const stroke = getIconColor(color)

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("check-circle-icon", className)}
    >
      <path
        d="M12.1354 8.125C10.8425 9.26618 9.84691 10.7365 9.2798 12.4048L7.5 10.625M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckCircle
