import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import { propertiesRentURL, propertiesSaleURL } from "@lib/urls"
import React from "react"
import "./PropertyBreadcrumb.scss"
import { slugify } from "@lib/utils"
import { updatePropertyTypePath } from "@lib/searchUtils"
import pluralize from "@lib/pluralize"
import { capitalize } from "lodash"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

const PropertyBreadcrumb = ({ property, title, description }) => {
  const {
    department,
    search_type,
    display_address,
    address,
    building,
  } = property

  const buildingValue = slugify(building[0])
  const propertyType = property?.building[0] || "Property"
  const isSales = property?.search_type === "sales"


  let backUrl = ""

  if (department === "residential" || department === "commercial") {
    if (search_type === "sales") {
      backUrl = `${propertiesSaleURL}`
    } else {
      backUrl = `${propertiesRentURL}`
    }
  }
  // else if (department === "commercial") {
  //   backUrl = commercialPropertiesRentURL
  // } else if (department === "new_developments") {
  //   backUrl = newHomesSaleURL
  // }

  const resultArray = [
    {
      name: address.address4,
      slug: slugify(address.address4),
    },
    {
      name: address.address3,
      slug: slugify(address.address3),
    },
    {
      name: address.address2,
      slug: slugify(address.address2),
    },
  ].filter((add) => add?.name !== "" && add?.name !== "Dubai")

  const breadcrumbURLs = [
    {
      url: backUrl,
      label: "Find a Property",
    },
    {
      url: updatePropertyTypePath(buildingValue, backUrl),
      label: `${capitalize(pluralize(buildingValue))} for ${
        search_type === "sales" ? "Sale" : "Rent"
      }`,
    },
    ...resultArray.map((add) => {
      return {
        url: `${backUrl}in-${add.slug}/`,
        label: add.name,
      }
    }),
    {
      url: "",
      label: property?.description?.data?.description || display_address,
    },
  ]
  return (
    <div className="property-breadcrumb-wrap">
      <Breadcrumbs staticURLs={breadcrumbURLs} propertydetails backButton title={title} description={description} property={property} />
      <Container>
      {!property?.publish && <div className="not-publish">
            <h3>Sorry, this Particular {`${propertyType} ${
            isSales ? "for Sale" : "for rent"
          } in ${property?.display_address}`} is no Longer Available as it has been Sold</h3>
          <p>However, we have hundreds of similar properties for you</p>
          <p>Please contact Our Specialist Agent Below for Similar Listings in {breadcrumbURLs[2]?.label}</p>
          <div className="bt">
            <Link to={breadcrumbURLs[2]?.url} className="button button-orange">View Similar Properties</Link>
          </div>

            </div>}
            </Container>
    </div>
  )
}

export default PropertyBreadcrumb
