import React from "react"

const WhatsAppIconWB = () => {
  return(
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M4.5 6.5C4.5 5.96957 4.71071 5.46086 5.08579 5.08579C5.46086 4.71071 5.96957 4.5 6.5 4.5L7.5 6.5L6.73 7.65438C7.03544 8.38421 7.61579 8.96456 8.34562 9.27L9.5 8.5L11.5 9.5C11.5 10.0304 11.2893 10.5391 10.9142 10.9142C10.5391 11.2893 10.0304 11.5 9.5 11.5C8.17392 11.5 6.90215 10.9732 5.96447 10.0355C5.02678 9.09785 4.5 7.82608 4.5 6.5Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.9956 13.1945C6.25594 13.9239 7.73853 14.1701 9.16697 13.8871C10.5954 13.6041 11.8722 12.8114 12.7593 11.6566C13.6464 10.5017 14.0832 9.06373 13.9883 7.61063C13.8935 6.15753 13.2734 4.78852 12.2437 3.75883C11.214 2.72915 9.84503 2.10907 8.39193 2.01422C6.93882 1.91936 5.50082 2.3562 4.34601 3.24328C3.1912 4.13037 2.39841 5.40715 2.11545 6.83559C1.83249 8.26403 2.07868 9.74662 2.80811 11.007L2.02623 13.3413C1.99685 13.4294 1.99259 13.524 2.01392 13.6144C2.03525 13.7047 2.08133 13.7874 2.147 13.8531C2.21266 13.9187 2.29532 13.9648 2.38571 13.9861C2.47609 14.0075 2.57063 14.0032 2.65873 13.9738L4.9956 13.1945Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default WhatsAppIconWB
